import React, { useEffect, useContext, useState } from 'react';
import { FormDataClientsContext } from '../../../contexts/FormDataClientsContext'
import M from 'materialize-css'

const ModalContacto = ({primary_color}) => {
	const { contactos, setContactos } = useContext(FormDataClientsContext)
	const [contacto, setContacto] = useState({nombre:'', puesto:'', telefono:'', extension:'', correo:'', enviar_factura:false})

	useEffect(() => {
		M.Modal.init(document.querySelectorAll('.modal'), {endingTop:'20%'});
	},[])

	const _continuar = (e) => {
		e.preventDefault()
		setContactos([...contactos, contacto])
		var instance = M.Modal.getInstance(document.querySelector('.modal'))
		instance.close()
		setContacto({nombre:'', puesto:'', telefono:'', extension:'', correo:'', enviar_factura:false})
	}

	return (
			<div id="modal1" className="modal" style={{borderRadius:20, width:'30%'}}>
				<div className="modal-content">
					<div className='title-sub'>
						<div className='title' style={{color:primary_color}}>AGREGAR CONTACTO</div>
					</div>
					<form onSubmit={_continuar}>
						<div className='row'>
							<div className='input-field'>
								<input value={contacto.nombre} onChange={(e) =>setContacto({...contacto, nombre:e.target.value})} placeholder='' id='contacto-nombre' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='contacto-nombre' style={{color:'black'}}>Nombre</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={contacto.puesto} onChange={(e) =>setContacto({...contacto, puesto:e.target.value})} placeholder='' id='contacto-puesto' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='contacto-puesto' style={{color:'black'}}>Puesto</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={contacto.telefono} onChange={(e) =>setContacto({...contacto, telefono:e.target.value})} placeholder='' id='contacto-telefono' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='contacto-telefono' style={{color:'black'}}>Telefono</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={contacto.extension} onChange={(e) =>setContacto({...contacto, extension:e.target.value})} placeholder='' id='contacto-extension' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}}/>
								<label htmlFor='contacto-extension' style={{color:'black'}}>Extensión</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={contacto.correo} onChange={(e) =>setContacto({...contacto, correo:e.target.value})} placeholder='' id='contacto-correo' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='contacto-correo' style={{color:'black'}}>Correo electrónico</label>
							</div>
						</div>
						<div className='row'>
							<label style={{color:'black'}}>
								¿Enviar factura?
							</label>
							<div className="switch">
								<label>
									No
									<input type="checkbox" checked={contacto.enviar_factura} onChange={(e) => setContacto({...contacto, enviar_factura:e.target.checked})}/>
									<span className="lever"></span>
									Si
								</label>
							</div>
						</div>
						<div className='row'>
							<button type='submit' className="waves-effect waves-light btn-flat white-text right" style={{background:'#093559', borderRadius:10, width:150, textAlign:'center'}}>Agregar</button>
						</div>
					</form>
				</div>
			</div>
			)
}

export default ModalContacto
