import React, { useEffect, useContext, useState } from 'react';
import { FormDataClientsContext } from '../../../contexts/FormDataClientsContext'
import ModalContacto from './modalContacto'
import { useHistory } from 'react-router-dom'
import axios from '../../../axios'

const Second = ({config}) => {

	const {data, domicilio, setDomicilio, contactos, setContactos, _clear} = useContext(FormDataClientsContext)
	const history=useHistory()
	const primary_color = config.primary_color
	const clientImg = config.img
	const [loader, setLoader] = useState(false)
	
	useEffect(() => {
		if(data.nombre==='')history.push('/')
	},[data, history])
	
	const _tanks = () => {
		alert('Gracias por usar nuestros servicios')
		_clear()
		history.push('/')
	}

	const _deleteContacto = (i) => {
		setContactos(contactos.filter((c,k)=>k!==i));
	}

	const _continuar = (e) => {
		e.preventDefault()
		if(contactos.length===0){
			alert('Agrege al menos un contacto')
			return
		}
		//data
		console.log(data)
		console.log(domicilio)
		console.log(contactos)
		const dataF = new FormData()

		dataF.append('nombre',data.nombre)
		dataF.append('giro',data.giro)
		dataF.append('rfc',data.rfc)
		dataF.append('correo',data.correo)
		dataF.append('web',data.web)
		dataF.append('acta_constitutiva',data.actaConstitutiva)
		dataF.append('constancia_situacionfiscal',data.constanciaSituacionFiscal)
		dataF.append('identificacion_representante',data.identificacionRepresentante)
		dataF.append('comprobante_domicilio',data.comprobanteDomicilio)

		dataF.append('domicilio',JSON.stringify(domicilio))
		dataF.append('contactos',JSON.stringify(contactos))

		setLoader(true)
		axios.post(config.endpoint_cdn,dataF)
		.then((r) => {
			setLoader(false)
			console.log(r.data)
			_tanks()
		})
		.catch((r) =>alert(r))

	}

	if(loader) return (
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)

	return (
		<div className='container' style={{maxWidth:550}}>
			<ModalContacto primary_color={primary_color}/>
			<center>
				<img src={clientImg} width='100' height='100' alt='img'/>
			</center>
			<div className='card-panel round' style={{padding:'50px 60px 30px 50px'}}>
				<center>
					<div style={{display:'flex', width:130, height:54}}>
						<a href='#!' className='btn-floating btn-large waves-effect waves-light' style={{width:31, height:31, lineHeight:'35px', background:'#E0E0E0', fontWeight:'bold'}}>1</a>
						<div style={{verticalAlign:'middle', background:'#E0E0E0', height:3, width:40, position:'relative', top:15}}></div>
						<a href='#!' className='btn-floating btn-large waves-effect waves-light' style={{width:31, height:31, lineHeight:'35px', background:primary_color, fontWeight:'bold'}}>2</a>
					</div>
				</center>
				<div className='title-sub'>
					<div className='title' style={{color:primary_color}}>DOMICILIO</div>
				</div>
				<div className='row'>
					<form onSubmit={_continuar}>
						<div className='row'>
							<div className='input-field'>
								<input value={domicilio.calle} onChange={(e) => setDomicilio({...domicilio, calle:e.target.value})} placeholder='' id='calle' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='calle' style={{color:'black'}}>Calle</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={domicilio.numExt} onChange={(e) => setDomicilio({...domicilio, numExt:e.target.value})} placeholder='' id='numExt' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='numExt' style={{color:'black'}}>Número exterior</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={domicilio.numInt} onChange={(e) => setDomicilio({...domicilio, numInt:e.target.value})} placeholder='' id='numInt' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}}/>
								<label htmlFor='numInt' style={{color:'black'}}>Número interior</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={domicilio.cp} onChange={(e) => setDomicilio({...domicilio, cp:e.target.value})} placeholder='' id='cp' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='cp' style={{color:'black'}}>Código postal</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={domicilio.colonia} onChange={(e) => setDomicilio({...domicilio, colonia:e.target.value})} placeholder='' id='colonia' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='colonia' style={{color:'black'}}>Colonia</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={domicilio.ciudad} onChange={(e) => setDomicilio({...domicilio, ciudad:e.target.value})} placeholder='' id='ciudad' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='ciudad' style={{color:'black'}}>Ciudad</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={domicilio.estado} onChange={(e) => setDomicilio({...domicilio, estado:e.target.value})} placeholder='' id='estado' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='estado' style={{color:'black'}}>Estado</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={domicilio.pais} onChange={(e) => setDomicilio({...domicilio, pais:e.target.value})} placeholder='' id='pais' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='pais' style={{color:'black'}}>País</label>
							</div>
						</div>
						<div className='row'>
							<div className='title-sub'>
								<div className='title' style={{color:primary_color}}>CONTACTOS</div>
							</div>
							<a className="btn-floating waves-effect white right modal-trigger" href='#modal1' style={{width:30, height:30, padding:0}}><i className="material-icons black-text " style={{fontSize:14,lineHeight:0, position:'relative',top:-3}}>add</i></a>
							<ul className="collection">
							{contactos.map((c,i) =>
								<li key={i} className="collection-item avatar" style={{paddingLeft:10}}>
									<span className="title">{c.nombre}</span>
									<p style={{fontSize:14, lineHeight:1.2}}>
										Teléfono: {c.telefono} <br />
										Correo:{c.correo} <br />
										Enviar factura: {c.enviar_factura?'Sí':'No'}
									</p>
									<a href="#!" className="secondary-content delete-circle" onClick={() =>_deleteContacto(i)}><i className="material-icons grey-text" style={{padding:8}}>delete</i></a>
								</li>
							)}
							</ul>
						</div>

						<button className='btn right waves-effect waves-light' style={{background:primary_color, borderRadius:10, textTransform:'none', width:150}}>Continuar</button>

					</form>
				</div>

			</div>
		</div>
	)
}

export default Second
