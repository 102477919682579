import React, { useEffect, useState } from 'react';
import axios from '../../../axios'

const MisClientes = ({primary_color}) => {

	const [clients, setClients] = useState([])
	const [search, setSearch] = useState('')

	useEffect(() => {
		axios.get('/api/myclients')
		.then((r) => {
			setClients(r.data)
			console.log(r.data)
		})
		.catch(r => alert(r))
	},[])

	return (
		<>
			<div>
				<div className='title-sub'>
					<div className='title' style={{color:primary_color}}>MIS CLIENTES</div>
				</div>
			</div>
			<nav>
				<div className="nav-wrapper white">
					<form>
						<div className="input-field">
							<input value={search} onChange={(e)=>setSearch(e.target.value)}id="search" type="search" placeholder='buscar por nombre' />
							<label className="label-icon" htmlFor="search"><i className="material-icons black-text">search</i></label>
							<i className="material-icons">close</i>
						</div>
					</form>
				</div>
			</nav>
			<div className='table1' style={{overflowX:'auto', transform:'scale(.9)'}}>
			<table>
				<thead>
					<tr>
						<th>#ID Cliente</th>
						<th>Nombre</th>
						<th>RFC</th>
						<th>Domicilio</th>
						<th>Correo</th>
						<th>Web</th>
						<th>Documentos</th>
						<th>Contactos</th>
						<th>Fecha de registro</th>
					</tr>
				</thead>
				<tbody>
				{clients.filter(c=>c.nombre.toLowerCase().includes(search.toLowerCase())).map((c,i) => 
					<tr key={i}>
						<td>{c.id}</td>
						<td>{c.nombre}</td>
						<td>{c.rfc}</td>
						<td>
						<div>
						<p>
						calle:{c.domicilio.calle} <br />
						numExt:{c.domicilio.numExt} <br />
						numInt:{c.domicilio.numInt} <br />
						ciudad:{c.domicilio.ciudad} <br />
						colonia:{c.domicilio.colonia} <br />
						cp:{c.domicilio.cp} <br />
						estado:{c.domicilio.estado} <br />
						pais:{c.domicilio.pais} <br />
						</p>
							
						</div>
						</td>
						<td>{c.correo}</td>
						<td>{c.web}</td>
						<td style={{width:'300px'}}>
						<a className='btn black' style={{display:'block', fontSize:12, lineHeight:'normal', padding:5}} href={c.acta_constitutiva} target='_blank' rel='noreferrer'>Acta Constitutiva</a>
						<a className='btn black' style={{display:'block', fontSize:12, lineHeight:'normal', padding:5}}href={c.comprobante_domicilio} target='_blank' rel='noreferrer'>Comprobante de Domicilio</a>
						<a className='btn black' style={{display:'block', fontSize:12, lineHeight:'normal', padding:10}}href={c.constancia_situacionfiscal} target='_blank' rel='noreferrer'>Situacion Fiscal</a>
						<a className='btn black' style={{display:'block', fontSize:12, lineHeight:'normal', padding:5}}href={c.identificacion_representante} target='_blank' rel='noreferrer'>Identificacion Representante</a>

						</td>
						<td>
							<ul className="collection">
							{c.contactos.map((c,i) =>
								<li key={i} className="collection-item">
									<span className="title">{c.nombre}</span>
									<p style={{fontSize:14, lineHeight:1.2}}>
										Teléfono: {c.telefono} <br />
										Correo:{c.correo} <br />
										Enviar factura: {c.enviar_factura?'Sí':'No'}
									</p>
									{/*
									<a href="#!" className="secondary-content"><i className="material-icons grey-text">delete</i></a>
									*/}
								</li>
							)}
							</ul>
						</td>
						<td>{c.created_at}</td>
					</tr>
				)}
				</tbody>
			</table>
			</div>
				
				

		</>
	)
}

export default MisClientes
