import React, { useEffect, useState } from 'react';
import axios from '../../axios'
import MisClientes from '../../components/admin/misclientes'
import M from 'materialize-css'
import { Route, Link, useHistory } from "react-router-dom";

const Dashboard = ({user, config}) => {

	const history = useHistory()
	const primary_color = config.primary_color
	const clientImg = config.img
	const [loader, setLoader] = useState(false)

	useEffect(() => {
		M.Collapsible.init(document.querySelectorAll('.collapsible'), {});
		M.Sidenav.init(document.querySelectorAll('.sidenav'), {});
	},[])

	const Hola = () => {
		return(
			<div style={{display:'flex',}}>
				<h4>Hola Admin {user.name}</h4>
			</div>
		)
	}

	const _salir = () => {
		setLoader(true)
		axios.get('/api/logout')
		.then(r=>{
			setLoader(false)
			history.push("/admin/login")
		})
		.catch(r=>alert(r))
	}

	const _open = () => {
		//document.getElementById("mySidebar").style.display = "block";
		document.getElementById("mySidebar").style.width = "300px";
		document.getElementById("mySidebar").style.borderRight = "solid rgb(211, 215, 207)";
	}
	const _close = () => {
		document.getElementById("mySidebar").style.width = "0px";
		document.getElementById("mySidebar").style.borderRight = "none";
		//document.getElementById("mySidebar").style.display = "none";
	}
	const _shadow = () => {
		setTimeout(() => {
			if(document.getElementById("collapsi").style.display==='block')
				document.getElementById("shadow").style.zIndex = "3"
				else document.getElementById("shadow").style.zIndex = "-1"
		},500)
	}

	if(loader) return (
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)

	return(
		<div style={{padding:20, minHeight:'100vh', background:'#F9F9F9'}}>
			<div className="row card-panel panel-round">
					<div className='sidebar' id="mySidebar">
						<a href='#!' className='close' onClick={_close}><i className="material-icons">close</i></a>
						<div style={{padding:20}}>
						<Link to='/admin' onClick={_close}>
							<img src={clientImg} width='100' height='100' alt='img'/>
						</Link>

						<ul className="collapsible" style={{color:primary_color}}>
							<li>
								<div className="collapsible-header"><i className="material-icons">dehaze</i>MENÚ</div>
								<div className="collapsible-body">
									<Link to="/admin/misclientes" onClick={_close} style={{color:primary_color}}>
										<i className="material-icons" style={{position:'relative',top:5, marginRight:10}}>people</i>
										Mis clientes
									</Link>
									<a onClick={_salir}href="#!" style={{color:primary_color}}>
										<i className="material-icons" style={{position:'relative',top:5, marginRight:10}}>exit_to_app</i>
										Salir
									</a>
								</div>
							</li>
						</ul>
						</div>
					</div>

				<div className="col s2" id='nav' style={{borderTopLeftRadius:32, paddingTop:20,borderBottomLeftRadius:32, height:'100%'}}>
				<div style={{height:'100%'}}>
					<Link to='/admin' style={{padding:20}}>
						<img src={clientImg} width='100' height='100'  alt='img'/>
					</Link>
					<ul className="collapsible" style={{color:primary_color}}>
						<li>
							<div className="collapsible-header" onClick={() =>_shadow()}><i className="material-icons">dehaze</i>MENÚ</div>
							<div id='collapsi' className="collapsible-body" style={{padding:0}} onClick={() => document.getElementById("shadow").style.zIndex = "-1"}>
								<Link to="/admin/misclientes" style={{color:primary_color}} >
									<i className="material-icons" style={{position:'relative',top:5, marginRight:10}}>people</i>
									Mis clientes
								</Link>
								<a onClick={_salir}href="#!" style={{color:primary_color}}>
									<i className="material-icons" style={{position:'relative',top:5, marginRight:10}}>exit_to_app</i>
									Salir
								</a>
							</div>
						</li>
					</ul>
				</div>
				</div>

				<div className="col s10" style={{padding:20, position:'relative'}}>
				<div id='shadow' onClick={() =>{document.getElementById("shadow").style.zIndex = "-1"}} style={{background:'rgba(0,0,0,.4)',height:'90vh', width:'100%', top:0, borderTopRightRadius:32, borderBottomRightRadius:32, position:'absolute', right:0, zIndex:-1, boxSizing:'content-box'}}></div>
					<a className='side-button' href='#!' onClick={_open} style={{color:primary_color}}><i className="material-icons">dehaze</i></a>
					<Route path='/admin' component={Hola} exact/>
					<Route path='/admin/misclientes' component={MisClientes} ><MisClientes primary_color={primary_color}/></Route>
				</div>
			</div>
		</div>
	)
}

export default Dashboard
