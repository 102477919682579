import React, { useState } from 'react';

const Arrastra = ({title, setImage}) => {

	const [file, setFile]=useState()

	const _loadFile = (file) => {
		if(file){
			setFile(file)
			console.log(file)
			setImage(file)
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload=()=>console.log(reader.result)
		}
	}

	const _clear = () => {
		setImage('')
		setFile()
	}

	return (
		<div className="file-field input-field" style={{height:130}}>
			<div style={{display:'flex',justifyContent:'center',alignItems: 'center',background:'#F8F8F8', height:100,width:200, padding:10, textAlign:'center', borderStyle:'dashed', border:'2px dashed grey', borderRadius:10}}>
				{title}
			</div>
			<input type="file" onChange={(e)=>_loadFile(e.target.files[0])} accept='image/x-png,image/gif,image/jpeg,application/pdf' required/>
			<div className="file-path-wrapper" style={{padding:0, display:'flex', alignItems:'center'}}>
				{file&&
				<>
				<div style={{textTransform:'uppercase',fontSize:9, color:'white', background:'grey', display:'flex', justifyContent:'center',alignItems:'center', width:20,height:20, borderRadius:3}}>
					{file.name.split('.')[1]}
				</div>
				<input className="file-path" type="text" style={{borderBottom:'none', margin:0, maxWidth:160, fontSize:12}}/>
				<a href='#!' onClick={_clear} className="waves-effect close-x"><i className="material-icons" style={{fontSize:12, color:'#8D8D8D'}}>close</i></a>
				</>
				}
			</div>
		</div>
	)
}

export default Arrastra
