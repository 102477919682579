import React from 'react';
import First from './first'
import Second from './second'
import { BrowserRouter as Router, Route, /*Link*/ } from "react-router-dom";
import { FormDataClientsProvider } from '../../contexts/FormDataClientsContext'

const Form = ({config}) => {

	return(
		<FormDataClientsProvider>
			<Router>
				<Route path='/' exact><First config={config}/></Route>
				<Route path='/second' exact><Second config={config}/></Route>
			</Router>
		</FormDataClientsProvider>
	)
}

export default Form
