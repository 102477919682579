import React, { useState, useEffect } from 'react';
import axios from '../../axios'
import Dashboard from './dashboard'
import Login from './auth/login'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

const Admin = ({config}) => {
	const [auth, setAuth] = useState(false)
	const [loader, setLoader] = useState(true)
	const [user, setUser] = useState({})

	useEffect(()=>{
		axios.get('/api/user').then(r=>{
			setAuth(true)
			setLoader(false)
			setUser(r.data)
			console.log(r.data)
		}).catch(r=>setLoader(false))
	},[])


	if(loader) return (
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)

	return(
			<Router>
			<Switch>
				<Route path="/admin/login" exact><Login setAuth={setAuth} setUser={setUser} config={config}/></Route>
				<Route path="/admin" render={({ location }) => auth ? <Dashboard user={user} config={config}/> : ( <Redirect to={{ pathname: "/admin/login", state: { from: location } }} />)} />
			</Switch>
			</Router>
	)
}

export default Admin
