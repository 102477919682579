import React, { useContext } from 'react';
import Arrastra from '../../arrastra'
import { useHistory } from "react-router-dom";
import { FormDataClientsContext } from '../../../contexts/FormDataClientsContext'

const First = ({config}) => {

	const history = useHistory()
	const {data, setData} = useContext(FormDataClientsContext);
	const primary_color = config.primary_color
	const clientImg = config.img

	const _continuar = () => {
		console.log(data)
		history.push('/second')
	}
	return (
		<div className='container' style={{maxWidth:537, paddingTop:25}}>
			<center>
				<img src={clientImg} height='100' width='100' alt='img'/>
			</center>
			<div className='card-panel round' style={{padding:'50px 58px 30px 58px'}}>
				<center>
					<div style={{display:'flex', width:130, height:54}}>
						<a href='#!' className='btn-floating btn-large waves-effect waves-light' style={{width:31, height:31, background: primary_color, fontWeight:'bold', lineHeight:'33px'}}>1</a>
						<div style={{verticalAlign:'middle', background:'#E0E0E0', height:3, width:40, position:'relative', top:15}}></div>
						<a href='#!' className='btn-floating btn-large waves-effect waves-light' style={{width:31, height:31, lineHeight:'33px', background:'#E0E0E0', fontWeight:'bold'}}>2</a>
					</div>
				</center>
				<div className='title-sub'>
					<div className='title' style={{color:primary_color}}>DAR DE ALTA UN CLIENTE</div>
				</div>
				<div className='row'>
					<form onSubmit={_continuar}>
						<div className='row'>
							<div className='input-field'>
								<input value={data.nombre} onChange={(e) =>setData({...data, nombre:e.target.value})} placeholder='' id='nombre-razon' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='nombre-razon' style={{color:'black'}}>Nombre o razón social</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={data.giro} onChange={(e) =>setData({...data, giro:e.target.value})} placeholder='' id='giro' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='giro' style={{color:'black'}}>Giro de la empresa</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={data.rfc} onChange={(e) =>setData({...data, rfc:e.target.value})} placeholder='' id='rfc' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='rfc' style={{color:'black'}}>RFC</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={data.correo} onChange={(e) =>setData({...data, correo:e.target.value})} placeholder='' id='correo' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}} required/>
								<label htmlFor='correo' style={{color:'black'}}>Correo</label>
							</div>
						</div>
						<div className='row'>
							<div className='input-field'>
								<input value={data.web} onChange={(e) =>setData({...data, web:e.target.value})} placeholder='' id='web' type='text' style={{borderBottom:'none', background:'#EBEBEB', borderTopRightRadius:20, borderBottomLeftRadius:20}}/>
								<label htmlFor='web' style={{color:'black'}}>Página web</label>
							</div>
						</div>
						<div className='row title-sub'>
							<div className='title' style={{color:primary_color}}>SUBIR ARCHIVOS</div>
						</div>
						<div>
							Se aceptan archivos <strong>.pdf, .jpg</strong> y <strong>.png</strong>
						</div>

						<div className='row'>
							<div className='col m6' style={{paddingLeft:0}}>
								<Arrastra title='Adjuntar acta constitutiva' setImage={(img) => setData({...data, actaConstitutiva:img})}/>
							</div>
							<div className='col m6' style={{paddingLeft:0}}>
								<Arrastra title='Adjuntar constancia de situación fiscal' setImage={(img) => setData({...data, constanciaSituacionFiscal:img})}/>
							</div>
							<div className='col m6' style={{paddingLeft:0}}>
								<Arrastra title='Adjuntar identificación oficial de la persona física o representante legal' setImage={(img) => setData({...data, identificacionRepresentante:img})}/>
							</div>
							<div className='col m6' style={{paddingLeft:0}}>
								<Arrastra title='Adjuntar comprobante de domicilio' setImage={(img) => setData({...data, comprobanteDomicilio:img})}/>
							</div>
						</div>
						<button className='btn right waves-effect waves-light' style={{background:primary_color, borderRadius:12, textTransform:'none', width:183, height:43}}>Continuar</button>
					</form>
				</div>

			</div>
		</div>
	)
}

export default First
