import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import axios from '../../../axios'

const Login = ({setAuth, setUser, config}) => {

	const [data, setData] = useState({email:'',password:''})
	const history = useHistory()
	const primary_color = config.primary_color
	const clientImg = config.img
	
	const _login = () => {
		axios.post('/api/login',data).then(r=>{
			localStorage.setItem('access_token', 'Bearer '+r.data.access_token);
			axios.defaults.headers.common['Authorization'] = 'Bearer '+r.data.access_token
			setAuth(true)
			setUser(r.data.user)
			setData({})
			history.push('/admin')
		}).catch(r=>alert(r))
	}

	const _showPassword = () => {
		if(document.getElementById("password").type==='password')
			document.getElementById("password").type='text'
		else document.getElementById("password").type='password'

	}

	return(
		<center style={{paddingTop:30}}>
			<img src={clientImg} width='100' height='100' alt='img'/>
			<div className="card-panel center" style={{backgroundColor: 'rgba(255,255,255,.7)', borderRadius:32, maxWidth:400}}>
				<div className='title-sub'>
					<div className='title' style={{color:primary_color}}>INICIA SESIÓN</div>
				</div>
				<div className="row">
					<div className="row" style={{marginBottom:0}}>
						<div className="input-field col s12">
							<i className="material-icons prefix" style={{color:'#625D5E', fontSize:20, marginTop:10}}>email</i>
							<input onChange={(e)=>setData({...data, email: e.target.value})} id="email" type="email" className="validate" />
							<label htmlFor="email">Correo electrónico</label>
						</div>
					</div>
					<div className="row">
						<div className="input-field col s12">
							<i className="material-icons prefix" style={{color:'#625D5E',fontSize:20,marginTop:10}}>lock</i>
							<input onChange={(e)=>setData({...data, password: e.target.value})} id="password" type="password" className="validate" />
							<i className="material-icons prefix" style={{color:'#625D5E',fontSize:20,marginTop:10,position:'absolute', right:10, cursor:'pointer'}} onClick={_showPassword}>remove_red_eye</i>
							<label htmlFor="password">Contraseña</label>
						</div>
					</div>
					<a href='#!' onClick={_login} className="btn waves-effect waves-light" style={{display:'block', borderRadius:10, background:primary_color}}>Entrar</a>
				</div>
			</div>
		</center>
	);
}


export default Login
