import React, { useState, useEffect } from 'react';
import axios from './axios'
import './sass/_index.scss';
import 'materialize-css'
import { BrowserRouter as Router, Route, /*Link*/ } from "react-router-dom";
import Form from './components/form'
import Admin from './components/admin'

const App = () => {

	const  [config, setConfig]= useState({});

	useEffect(() => {
		axios.get('/api/config')
		.then((r) => {
			setConfig(r.data)
		})
		.catch((r)=>alert(r))
	},[])

	return (
		<div className="App">
			<Router>
				<Route path='/'><Form config={config} /></Route>
				<Route path='/admin'><Admin config={config}/></Route>
			</Router>
		</div>
	);
}

export default App;
