import React, { useState, createContext } from 'react';

export const FormDataClientsContext = createContext()
export const FormDataClientsProvider = props => {
	const [data, setData] = useState({ nombre:'', giro:'', rfc:'', correo:'', web:'', actaConstitutiva:'', constanciaSituacionFiscal:'', identificacionRepresentante:'', comprobanteDomicilio:'' })
	const [domicilio, setDomicilio] = useState({calle:'', numExt:'', numInt:'', cp:'', colonia:'', ciudad:'', estado:'', pais:''})
	const [contactos, setContactos] = useState([]) //{nombre:'', puesto:'',telefono:'', extension:'', correo:'', enviar_factura:false}

	const _clear = () => {
		setData({ nombre:'', giro:'', rfc:'', correo:'', web:'', actaConstitutiva:'', constanciaSituacionFiscal:'', identificacionRepresentante:'', comprobanteDomicilio:'' })
		setDomicilio({calle:'', numExt:'', numInt:'', cp:'', colonia:'', ciudad:'', estado:'', pais:''})
		setContactos([])
	}

	return (
		<FormDataClientsContext.Provider value={{data, setData, domicilio, setDomicilio, contactos, setContactos, _clear}}>
			{props.children}
		</FormDataClientsContext.Provider>
	)
}
